import * as React from 'react';
import { Fragment, useCallback, useEffect } from 'react';
import {
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    ExportButton, FieldProps, Link,
    List, NumberField, ReferenceField,
    ReferenceManyCount,
    SelectColumnsButton,
    TextField,
    TopToolbar, useGetOne,
    useListContext, useRecordContext,
} from 'react-admin';
import { Divider, Tabs, Tab, Tooltip } from '@mui/material';
import ThumbnailField from "../../common/ThumbnailField";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import Button from "@mui/material/Button";
import { Description, Videocam, PictureAsPdf, Edit, DocumentScanner } from '@mui/icons-material';
import { Contents, Questions, Tests } from "../../custom";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import StatusTags from "../../common/StatusTags";
import { BulkUploadExerciseQuestionsAction } from "./BulkUploadExerciseQuestions";


const ListActions = () => {
    const { subjectId, programId, topicId, chapterId } = useParams();
    const navigate = useNavigate();
    return <TopToolbar>
        <Button
            startIcon={<Videocam />}
            onClick={() => {
                navigate(`/programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/${topicId}/contents/create?type=LECTURES`)
            }}
            sx={{ padding: '0 5px' }}>
            Add a Video
        </Button>
        <Button
            startIcon={<PictureAsPdf />}
            onClick={() => {
                navigate(`/programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/${topicId}/contents/create?type=NOTES`)
            }}
            sx={{ padding: '0 5px' }}>
            Add a Note
        </Button>
        <Button
            startIcon={<Description />}
            onClick={() => {
                navigate(`/programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/${topicId}/contents/create?type=EXERCISES`)
            }}
            sx={{ padding: '0 5px' }}>
            Add an Exercise
        </Button>
        <SelectColumnsButton />
        <ExportButton />
    </TopToolbar>
};

const ContentList = () => {
    const { subjectId, programId, topicId, chapterId } = useParams();
    if (!topicId) {
        return <LoadingSection />
    }
    return <List
        filterDefaultValues={{
            type: 'LECTURES',
            program_id: programId,
            chapter_id: chapterId,
            subject_id: subjectId,
            topic_id: topicId
        }}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        resource={'contents'}
        filters={contentFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>
};

const contentFilters: any[] = [];

const tabs = [
    { id: 'LECTURES', name: 'Lectures' },
    { id: 'NOTES', name: 'Notes' },
    { id: 'EXERCISES', name: 'Exercises' },
];

const EditButton = () => {
    const navigate = useNavigate();
    const record: Contents = useRecordContext<Contents>();
    return <Button startIcon={<Edit />} onClick={(e) => {
        navigate(`/programs/${record.program_id}/subjects/${record.subject_id}/chapters/${record.chapter_id}/topics/${record.topic_id}/contents/${record.id}/edit`)
    }}>
        Edit
    </Button>

};



const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;
    const { subjectId, programId, topicId, chapterId } = useParams();

    useEffect(() => {
        if (subjectId && programId && topicId && chapterId) {
            setFilters(
                {
                    ...filterValues, ...{
                        program_id: programId,
                        chapter_id: chapterId,
                        subject_id: subjectId,
                        topic_id: topicId
                    }
                },
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            );
        }
    }, [subjectId, programId, topicId, chapterId])
    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, type: value, },
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    const QuestionPaperViewPage = (props: FieldProps<Tests>) => {
        const record = useRecordContext<any>();
        
        if (!record) {
            return null;
        }

        return (
            <Link
                to={`/programs/${record.program_id}/exercise/${record.exercise_id}/tests/${record.exercise_id}/question_paper`}>
                <Tooltip title="Show question paper">
                    <Button onClick={(e) => {
                    }}>
                        <DocumentScanner />
                    </Button>
                </Tooltip>
            </Link>
        );
    };

    QuestionPaperViewPage.defaultProps = {};


    const QuestionsLinkField = (props: FieldProps<Tests>) => {
        const record = useRecordContext<any>();
        if (!record) {
            return null;
        }
        return (
            <Link to={`/programs/${record.program_id}/exercise/${record.exercise_id}/tests/${record.exercise_id}/questions_list`}>
                Questions
                (
                <Count resource="questions" filter={{ test_id: record?.exercise_id }} />
                )
            </Link>
        );
    };

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.type}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {choice.name} (<Count
                                    filter={{
                                        ...filterValues,
                                        type: choice.id,
                                    }}
                                    sx={{ lineHeight: 'inherit' }}
                                />)
                            </div>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <>
                {
                    filterValues.type === 'LECTURES' && (
                        <DatagridConfigurable
                            omit={['type']}

                        >
                            {/* <ThumbnailField source="thumbnail" /> */}
                            <TextField source="title" />
                            <StatusTags source="status" label={'Status'}/>
                            {/*<AddEditVideoModalAction/>*/}
                            <NumberField source="display_order" />
                            <BooleanField
                                source="is_demo"
                            />

                            <FormattedDateTimeField source="created_at" />

                            <EditButton />
                            <DeleteWithDialogAction />
                        </DatagridConfigurable>
                    )}
                {
                    filterValues.type === 'NOTES' && (
                        <DatagridConfigurable
                            omit={['type']}

                        >
                            <TextField source="title" />
                            <StatusTags source="status" label={'Status'}/>
                            <NumberField source="display_order" />
                            <BooleanField
                                source="is_demo"
                            />
                            <FormattedDateTimeField source="created_at" />

                            <EditButton />
                            <DeleteWithDialogAction />
                        </DatagridConfigurable>
                    )}
                {
                    filterValues.type === 'EXERCISES' && (
                        <DatagridConfigurable
                            omit={['type']}
                        >
                            <TextField source="title" />
                            <StatusTags source="status" label={'Status'}/>
                            <NumberField source="display_order" />
                            <BooleanField
                                source="is_demo"
                            />
                            <FormattedDateTimeField source="created_at" />
                            <QuestionPaperViewPage />
                            <QuestionsLinkField />
                            <BulkUploadExerciseQuestionsAction />
                            <EditButton />
                            <DeleteWithDialogAction />
                        </DatagridConfigurable>
                    )}
            </>
        </Fragment>
    );
};

export default ContentList;

