import * as React from 'react';
import {
    List,
    EditButton,
    Datagrid,
    TextField,
    ReferenceField,
    useRecordContext,
    TextInput,
    Filter,
    useNotify,
    FieldProps,
    useUpdate,
    useRefresh,
    TopToolbar,
    CreateButton,
    SelectInput,
    ReferenceInput,
    useListContext,
    Link,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import Lottie from "lottie-react";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { Button, IconButton, Typography } from '@mui/material';
import {
    Add,
    ChangeCircle,
    CheckBox, CloudUpload,
    CopyAll, History,
    Schedule,
    Settings,
    SyncProblem,
    ViewCozy,
} from "@mui/icons-material";
import { Tests } from "../../custom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router";
import lottie from "../../settings.json";
import { displayDuration } from "../../common/string-utilities";
import { BASE_URL, VIDEO_QUALITIES, VIDEO_TYPES } from "../../common/constants";

const VideoFilter = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput variant={'outlined'} label="Title" source="title" alwaysOn={true} defaultValue="" />
            <TextInput variant={'outlined'} label="Code" source="code" defaultValue="" alwaysOn={true} />
            <ReferenceInput label="Video Subject" source="video_subject_id" alwaysOn reference="video_subjects">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                alwaysOn={true}
                choices={VIDEO_TYPES}
            />
        </Filter>
    );
}
const CopyUrl = (props: any) => {
    const record = useRecordContext();
    return <Button disabled={!record[props['source']]} startIcon={<CopyAll />}
        onClick={() => navigator.clipboard.writeText(record[props['source']])}>
        Copy
    </Button>
}
const ProcessingStatus = (props: any) => {
    const record = useRecordContext();
    let icon = <SyncProblem color={'error'} />;
    const navigate = useNavigate();
    if (record['status'] === 'PROCESSED') {
        icon = <CheckBox color={'success'} />
    }
    if (record['status'] === 'QUEUED') {
        icon = <Schedule color={'info'} />
    }
    if (record['status'] === 'PROCESSING') {
        icon = <div className={'w-8 h-8'}>
            <Lottie animationData={lottie} loop={true} />
        </div>;
    }
    return <div className={'flex items-center space-x-2'}>
        {icon}
        <div onClick={() => navigate(`/videos/${record.id}/show/logs`)}
            className={'rounded bg-gray-500 text-white px-2 cursor-pointer hover:bg-gray-600'}>
            Logs
        </div>
    </div>
}
export const VideoThumbnail = () => {
    const record = useRecordContext();
    const notify = useNotify();
    return <div className={'flex space-x-2 items-center'}>
        <div>
            <div
                className={'min-h-[45px] min-w-[100px] aspect-video rounded overflow-hidden bg-gray-100 border bg-center bg-contain bg-no-repeat relative'}
                style={{ backgroundImage: `url(${record.thumbnail})` }}>
                <div className={'p-2 text-center absolute -bottom-0 font-semibold bg-white px-1.5 py-0.5 rounded-md text-xs items-center flex space-x-0.5'}>
                    <Schedule style={{ fontSize: '12px' }} /> <div>{displayDuration(record.duration_in_seconds)}</div>
                </div>
            </div>
        </div>
        <div className={'flex flex-col items-start'}>
            <div className={'font-medium'}>
                {record.title}
            </div>
            <div
                className={'text-xs flex space-x-2 items-center cursor-pointer rounded-md transition-all duration-200 hover:bg-indigo-50 px-2 py-1 mt-1'}
                onClick={() => {
                    navigator.clipboard.writeText(record.code);
                    notify(
                        'Code Copied',
                        {
                            type: 'success'
                        }
                    );
                }}>
                <CopyAll fontSize={'small'} />
                <div>
                    {record.code}
                </div>
            </div>
        </div>
    </div>
}
VideoThumbnail.defaultProps = {
    label: 'Video'
}
export const VideoQualitiesEncodings = () => {
    const record = useRecordContext();
    const qualities = record['qualities'] || [];
    const encodings = record['encodings'] || [];
    const drmEnabled = !!record['drm_enabled'];
    return <div className={'flex flex-col '}>
        {
            qualities?.length > 0 ? <div className={'flex space-x-1 items-center'}>
                <Settings style={{ fontSize: '14px' }} />
                {
                    qualities.map((quality: string) => {
                        return <div key={quality} className={'text-xs px-1 py-0.5 rounded-md bg-gray-50'}>
                            {quality}
                        </div>
                    })
                }
            </div> : <></>
        }
        {
            encodings?.length > 0 ? <div className={'flex space-x-1 items-center'}>
                <ViewCozy style={{ fontSize: '14px' }} />
                {
                    encodings.map((encoding: string) => {
                        return <div key={encoding} className={'text-xs px-1 py-0.5 rounded-md bg-gray-50'}>
                            {encoding}
                        </div>
                    })
                }
            </div> : <></>
        }
        {
            <div className={'flex space-x-1 items-center'}>
                {
                    record.type === 'YOUTUBE' ? <div className={'text-xs px-1 py-0.5 rounded-md bg-red-500 text-white'}>
                        Youtube
                    </div> : <>
                        {
                            drmEnabled ? <div className={'text-xs px-1 py-0.5 rounded-md bg-blue-500 text-white'}>
                                DRM
                            </div> : <>
                                {record.type === "SECURE" ? (
                                    <div className="text-xs px-1 py-0.5 rounded-md bg-amber-500 text-white">
                                        Secure
                                    </div>
                                ) : record.type === "SECURE_V2" ? (
                                    <div className="text-xs px-1 py-0.5 rounded-md bg-purple-500 text-white">
                                        Secure V2
                                    </div>
                                ) : (
                                    <div className="text-xs px-1 py-0.5 rounded-md bg-purple-500 text-white">
                                        Super Secure
                                    </div>
                                )}
                            </>

                        }
                    </>
                }

            </div>
        }
    </div>
}
VideoQualitiesEncodings.defaultProps = {
    label: 'Metadata'
}

const ReProcessIcon = () => {
    const record = useRecordContext();
    const [loading, setLoading] = React.useState(false);
    const refresh = useRefresh();
    if (!(record['status'] === 'QUEUED' && record.type === 'CF_SECURE')) {
        return <></>
    }
    return <Button disabled={loading} onClick={() => {
        fetch(BASE_URL + `/videos/${record.id}/cf-secure`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then(() => {
            refresh();
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        });
    }}>
        <ChangeCircle />
    </Button>
}
const Actions = (props: FieldProps<Tests>) => {
    return (
        <div className={'flex'}>
            {/*<ShowIcon/>*/}
            <ReProcessIcon />
            <EditButton />
            <DeleteWithDialogAction />
        </div>
    );
};

Actions.defaultProps = {
    label: 'Actions'
};
const ListActions = () => {
    const { subjectId, programId, topicId, chapterId } = useParams();
    const navigate = useNavigate();
    const { filterValues } = useListContext();

    // Convert filter values to query string
    const query = new URLSearchParams(filterValues).toString();

    return (
        <TopToolbar>
            <Button
                startIcon={<CloudUpload />}
                onClick={() => {
                    navigate(`/videos/bulk?${query}`);
                }}
                sx={{ padding: '0 5px' }}>
                Bulk Upload
            </Button>

            <Link
                to={`/videos/create?${query}`}
                style={{ textDecoration: 'none' }} // Remove underline from the link
            >
                <Button
                    variant="text" // Use "text" variant to remove background and border
                    startIcon={<Add />}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'rgba(0, 0, 0, 0.87)', // Default text color
                        border: 'none', // Remove border
                        backgroundColor: 'transparent', // Remove background color
                        padding: '8px 16px', // Adjust padding if needed
                        fontSize: '14px' // Adjust font size to match typical button size
                    }}
                >
                    <Typography variant="button">Create</Typography>
                </Button>
            </Link>

        </TopToolbar>
    );
};

const VideoList = () => {
    return (
        <div>
            <List actions={<ListActions />} sort={{ field: 'code', order: 'DESC' }} filters={<VideoFilter />}>
                <Datagrid>
                    <VideoThumbnail />
                    <TextField source={'is_downloadable'} />
                    <VideoQualitiesEncodings />
                    <CopyUrl source={'processed_url'} />

                    <ReferenceField reference={'video_subjects'} source={'video_subject_id'}>
                        <TextField source={'name'} />
                    </ReferenceField>
                    <ProcessingStatus source={'processing_status'} />
                    <FormattedDateTimeField source="created_at" />
                    <Actions />
                </Datagrid>
            </List>
        </div>
    );
};
export default VideoList;
